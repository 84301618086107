
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import {Redirect, Route,Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { createStyles,makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { burnActions } from 'store/burn-slice';
import { Button, createTheme, SlideProps } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import BurnButtons from './BurnButtons';
import BurnsTable from './BurnsTable';
import ScenariosLayout from './ScenariosLayout';
import ScenariosTable from './ScenariosTable';
import ModelsTable from './ModelsTable';




interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const tabTheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {  
        padding: '1px 1px 1px 1px',
      },
    },    
  }
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& .MuiBox-root': {
      padding: '2px',
      }
  },
  button:{
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  paper: {
    // height:800,
    // width:950
  },
  mapPaper:{
    height:400,
  },
  control: {
    padding: theme.spacing(1)
  },
    tab: { 
      padding:"10px"
      },
}));
function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface FullWidthTabProps {
   
    onAddNewBurn:any;
    mapDispatcher:any;
  }

export default function FullWidthTabs( props: FullWidthTabProps ) {
    // const { displayData, ...other } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
  const [value, setValue] = React.useState(0);
  
  const {onAddNewBurn, mapDispatcher} = props;

  const activateScenariosTab = () =>{
    setValue(2);
 }

const focusScenariosTabHandler = () => {
  setValue(2);
}
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
 const buildScenarioHandler =()=>{
  dispatch(burnActions.updateScenarioBurnItems());
  setValue(1);
 }

 const onFocusScenariosLayoutTabHandler = () =>{
  setValue(1);
 }

  return (
    <div className={classes.root} >     
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"            
        >
          <Tab  className={ classes.tab }  label="Master Burn List" {...a11yProps(0)} />
          <Tab className={ classes.tab }  label="Build Selection" {...a11yProps(1)} />
          <Tab className={ classes.tab }  label="Existing Selections" {...a11yProps(2)} />  
          <Tab  className={ classes.tab } label="TAPM Models" {...a11yProps(3)} />          
        </Tabs>      
        <TabPanel value={value} index={0} > 
          <BurnButtons />
          <Paper className={classes.paper}>       
            <BurnsTable mapDispatcher={mapDispatcher} />                  
          </Paper>
              <Button size="small"  style={{ maxHeight: '30px',  minHeight: '30px'}} className={classes.button} variant="outlined" onClick ={buildScenarioHandler}>Build Selection</Button>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ScenariosLayout onFocusScenariosTab ={focusScenariosTabHandler} mapDispatcher={mapDispatcher}/>
        </TabPanel>
        <TabPanel  value={value} index={2}>
        <ScenariosTable onFocusScenariosLayoutTab={onFocusScenariosLayoutTabHandler} />
        </TabPanel>
        <TabPanel value={value} index={3}>
        <ModelsTable  />
        </TabPanel>
      
    </div>
  );
}
