/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EditIcon from '@material-ui/icons/Edit';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import configData from "config.json";
import { Checkbox, 
  createTheme, 
  createStyles, 
  FormControlLabel, 
  Snackbar, 
  Switch, 
  TablePagination, 
  Theme, 
  ThemeProvider } from "@material-ui/core";
  
import SearchBar from "material-ui-search-bar";
// @ts-ignore
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { burnActions } from "store/burn-slice";
import { Guid } from "guid-typescript";
// @ts-ignore
import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';
import moment from "moment";
import Polygon from "@arcgis/core/geometry/Polygon";
import { useOktaAuth } from "@okta/okta-react";

import {formatTimeforTableCell as formatTime} from '../helper/helperService';


const wkt = require('wkt');
const { parse } = require('wkt');

export const StyledSearchBar = styled(SearchBar)`
  max-width: 475px;
`;

const useRowStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  },
 
})
);

const tabletheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {  
        padding: '1px 1px 1px 1px',
      },
    },
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    searchbar:{
      width:475,      
      marginBottom: theme.spacing(2)
    },

  })
);

function Row(props: { row: any ; isChecked:boolean; onRowClicked: (rowId:string, rowItem: any)=>void; focusScenariosLayout: ()=>void}) {
  const dispatch = useDispatch();
  const { row, isChecked, focusScenariosLayout, onRowClicked } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const burnData =  useSelector((state: RootState) => state.burn.burnItemsAPI); // saved burns
  const scenarioData = useSelector((state: RootState) => state.burn.scenarioItems); // saved scenarios

async function fetchSelectedSelection(rowId:string,isClone:boolean){
  try{
     const getSelectedScenario = await fetch(`${process.env.REACT_APP_SCENARIO_URL!}/${rowId}`,
    // const getSelectedScenario = await fetch(`https://smoke2-api.dev.apps.rfs.nsw.gov.au/api/scenario/e447f0ce-feaa-961d-93ce-235bc99f5a9a-201`, // -201 addl, to generate err - no such id
      {headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
      }
    );
    if (getSelectedScenario.ok) {
      const selectedScenarioJSON = await getSelectedScenario.json();
      const selectedScenarioJSONCopy = {...selectedScenarioJSON};
      if(isClone){
        selectedScenarioJSONCopy.id='';
      }
      const burnIDs = selectedScenarioJSON.burns.map((burnItem:any) => burnItem.id);
      let burnItems = [] as any;

      burnItems = burnData.filter((burnDataItem:any) => burnIDs.some((burnRowId:any) => burnRowId === burnDataItem.id ));
      const updatedScenarioBurns = {
        ...selectedScenarioJSONCopy,
        burns:burnItems
      }

      dispatch(burnActions.updateSelectedScenario(updatedScenarioBurns));
      focusScenariosLayout();
  }
  else{
    console.log('Error fetching data- burns');
  }
  }
  catch(err){
    console.log('Error fetching data- scenario');
    console.log(err);
  }
}







const onEditSelectionHandler = (rowItem:any)=>{
  fetchSelectedSelection(rowItem.id,false);
}

const onCloneSelectionHandler = (rowItem:any) =>{
  fetchSelectedSelection(rowItem.id,true);
}


  const handleCheckboxClick =(event: any, id: string, rowItem:any) => {
    onRowClicked(id, rowItem);
  }
  const isItemSelected = isChecked;
  return (
    <>
      <TableRow
      hover
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      >
      <TableCell padding="checkbox">
        <Checkbox
          onChange={(event) => handleCheckboxClick(event, row.id, row)}
          role="checkbox"
          checked={isItemSelected }
          inputProps={{ "aria-labelledby": row.id }}
        />
      </TableCell>
      <TableCell>
        <IconButton title="Edit Selection" aria-label="Edit Selection" onClick={() => {onEditSelectionHandler(row)}}>
          <EditIcon color="primary" />
        </IconButton>
        </TableCell>
        <TableCell>
        <IconButton title="Clone Selection" aria-label="Clone Selection" onClick={() => {onCloneSelectionHandler(row)}}>
          <FileCopyIcon color="primary" />
        </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" >
          {row.description}
        </TableCell>
        <TableCell align="right">{formatTime(row.startDate)}</TableCell>
        <TableCell align="right">{formatTime(row.endDate)}</TableCell>
        <TableCell align="right">{row.particleSize}</TableCell>
        <TableCell align="right">{row.domain}</TableCell>
        <TableCell align="right">{row.weatherSource}</TableCell>       
        {/* <TableCell align="right">{row.domainLocation}</TableCell> */}
      </TableRow>
      <TableRow
      hover
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Burns 
              </Typography>
              <Table size="small" aria-label="burns">
                <TableHead>
                  <TableRow>
                    <TableCell  size="small">Burn Description</TableCell> 
                    <TableCell>Guar Ref</TableCell> 
                    <TableCell>Burn Activity Type</TableCell>  
                    </TableRow>
                </TableHead>
                <TableBody>
                  {row.burns.map((burnListRow:any) => {
                    const burnItem = burnData.filter((burnDataItem:any)=>burnDataItem.id === burnListRow.id);
                    return (
                    <TableRow key={burnListRow.id}>
                      <TableCell   component="th" scope="row">
                        {burnItem[0]?.description}
                      </TableCell> 
                      <TableCell   component="th" scope="row">
                        {burnItem[0]?.guarReference}
                      </TableCell> 
                      <TableCell>{burnItem[0]?.activityType}</TableCell>                       
                    </TableRow>
                  )
                      })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ScenariosTable( props:{onFocusScenariosLayoutTab : ()=>void}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState<string[]>([]);

  const scenarioData = useSelector((state: RootState) =>   state.burn.scenarioItems);
  const burnData = useSelector((state: RootState) =>   state.burn.burnItemsAPI);
  const selectedScenarioId = useSelector((state: RootState) =>   state.burn.selectedScenarioId);
  // const rows = scenarioData;
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = useState<any>(scenarioData);
  const [searched, setSearched] = useState<string>("");

  const [exportDisabled, setExportDisabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const { authState, oktaAuth } = useOktaAuth();

  // Equivalent to isAuthenticated
  const isAuthenticated = authState?.isAuthenticated;

  // Equivalent to user (retrieved from ID token claims)
  const user = authState?.idToken?.claims;


  const requestSearch = (searchedVal: string) => {
    const filteredRows = scenarioData.filter((row:any) => {
      return row.description.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    if(selected.length>0){
      const selectedId = selected[0];
      dispatch(burnActions.updateSelectedScenarioId(selected[0]));
      
      const selectedScenario = scenarioData.find((scenarioItem:any)=>scenarioItem.id === selectedId);
      let disableExport = true;
      let disableSubmit = true;    
      if(selectedScenario){
        disableExport = selectedScenario.isAqfx !== true;
        disableSubmit = selectedScenario.isTapm !== true;
      }
      setExportDisabled(disableExport);
      setSubmitDisabled(disableSubmit);
    }
    else{
      setExportDisabled(true);
      setSubmitDisabled(true);
    }
  }, [selected]);

  useEffect(()=>{
    setRows(scenarioData);
  },[scenarioData]);

  const handleSnackbarClose = (event:any, reason:any) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const fetchFormattedSelection = ():any =>{     
     const selectedSelection = scenarioData.find((selectionItem:any)=>selectionItem.id === selectedScenarioId);
     if(!selectedSelection){ 
       console.log(`Couldnt find selection with id ${selectedScenarioId}`);
       return null;
     }
  
    const burnIDs = selectedSelection.burns.map((burnItem:any) => burnItem.id);
    let burns = [] as any;
  
    burns =burnData.filter((burnDataItem:any) => burnIDs.some((burnRowId:any) => burnRowId === burnDataItem.id ));
    
    const selectionStartAt = selectedSelection.startTime;
    const selectionEndAt = selectedSelection.startTime;
    
    const formatedBurnData = [] as any;
    burns.forEach((burnItem:any)=>{
        const geometryVal = burnItem.geometry;
        // if (geometryVal === null) return;
        const wktGeom = wkt.parse(geometryVal);
        const esriGeom = geojsonToArcGIS(wktGeom);
        
        // when converting back to esri polygon, somehow spatailReference is changed to 4826, so setting it back to 102100
        const burnPolygon = new Polygon({  
          rings: esriGeom.rings,
          spatialReference: {
            wkid: 102100 
          }
        });
    
        const {latitude, longitude} = burnPolygon.centroid;
        // const burnDays = [] as any;
        const formattedBurnDays = burnItem.burnList.map((burnDay:any)=>{     
            const formattedItems = {
              start_at: moment(burnDay.startDateTime,"YYYY-MM-DDTHH:mm:ss").utcOffset(0,false).toISOString(),
              end_at:moment(burnDay.endDateTime,"YYYY-MM-DDTHH:mm:ss").utcOffset(0,false).toISOString(),
              burn_name: `Day${burnDay.id}`,
              burn_intensity:burnItem.burnIntesity.toString().toLowerCase(),
              burn_area:burnDay.area,
              fuel_load:burnItem.fuelLoad,
              fuel_type:burnItem.fuelType.toString().toLowerCase(),
              hr_reference: '', // TO DO HR_REF
              burn_geometry:{
              latitude,
              longitude
              }
            }; 
            
            return formattedItems;
     });
     formatedBurnData.push (...formattedBurnDays);
      
    });    
    const modelId = Guid.create().toString();
    const {latitude, longitude} = selectedSelection.domainLocation;
    const timeNow = `${moment().utcOffset(0, true).toISOString().split('.')[0]}Z`;
    const formatedSelectionObject = {
      id: modelId, 
      // scenario_id: selectedScenarioId,
      model_name: selectedSelection.description, 
      request_status: "new", 
      model_geometry: {             
        longitude,
        latitude
      },
      requested_at: timeNow, 
      requested_by: user ? user.name :  "", 
      model_json_url: '' ,
      wind_json_url: "", 
      analyst_comment: "", // TODO
      output_particle_size: selectedSelection.particleSize.toString().toLowerCase().replace('pm',''), // selection size
      model_burns: formatedBurnData,
      model_start_at: moment(selectedSelection.startDate,"YYYY-MM-DDTHH:mm:ss").utcOffset(0,false).toISOString(),  // converting to utc as the smoke model creation api expects utc time  
      model_end_at: moment(selectedSelection.endDate,"YYYY-MM-DDTHH:mm:ss").utcOffset(0,false).toISOString(),      // converting to utc as the amoke model creation api expects utc time
      is_small: selectedSelection.domain.toString().toLowerCase()==='small',                           
      weather_data: selectedSelection.weatherSource.toString().toLowerCase(),                     
      processing_finished_at: timeNow, 
      run_model: true                              
    }

    return formatedSelectionObject;  
  
  }

  async function exportScenario (){
    try{
        const response = await fetch(`${process.env.REACT_APP_EXPORT_URL!}/${selectedScenarioId}`);
        if (response.ok) {
          setMessage("Successfully submitted to export scenario..");
          setSnackbarOpen(true);
          setSeverity("success");
        } else {
          setMessage("There was some issue while submiting export..");
          setSnackbarOpen(true);
          setSeverity("warning");
        }
        // response.json().then(data => console.log(data));
            }
        catch (err){
          console.log(err);
        setMessage("Error exporting scenario");
        setSnackbarOpen(true);
        setSeverity("error");
      }
  }

  async function reloadModels(){
    try{
      const fetchModels = await fetch(process.env.REACT_APP_MODEL_URL!,
      {headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
      }
      );
      if(fetchModels.ok){
        const refreshedModelData = await fetchModels.json();    
        dispatch(burnActions.updateModelItems(refreshedModelData));
      }
      else{
        console.log("error while fetching models..")
      }
    }
    catch (err){
      console.log("error while fetching models..");
      console.log(err);    }
  }

  async function submitScenario () {
    try{
        const formattedSelection = fetchFormattedSelection();
        // console.log(formattedSelection);
       
         const response = await fetch(process.env.REACT_APP_MODEL_URL!,
           {
            method:'POST',
            body:JSON.stringify(formattedSelection),
            headers:{
              'Accept': 'application/json',
              'Content-Type':'application/json'
            }
          });

          if (response.ok) {
            setMessage("Successfully submitted selection..");
            setSnackbarOpen(true);
            setSeverity("success");
            reloadModels();
          }
          else{
            setMessage("Error submiting selection..");
          setSnackbarOpen(true);
          setSeverity("warning");
          }
            }
        catch (err){
          console.log(err);
        setMessage("Error submiting selection");
        setSnackbarOpen(true);
        setSeverity("error");
      }
  }

  const formatSelectionObjectForSubmission = () => {
    const modelId = Guid.create().toString();
    const formatedSelectionObject = {
      id: modelId, 
      // scenario_id: selectedScenarioId,
      model_name: "DynamoDB-Testing", // selection name
      request_status: "validated", //
      model_geometry: {             // domain
        longitude: 151.04105948933585,
        latitude: -33.516619870517246
      },
      requested_at: "", // "2021-06-10T04:25:58.381Z", //current time
      requested_by: "", // "Nogy Augustine", //current login name
      model_json_url: '' , // "https://s3-ap-southeast-2.amazonaws.com/au.gov.nsw.smoke.output.dev/3357a944e98948b481958cff.json",
      wind_json_url: "", // "https://s3-ap-southeast-2.amazonaws.com/au.gov.nsw.smoke.output.dev/3357a944e98948b481958cff_w.json",
      analyst_comment: "", // "Checked", //
      output_particle_size: 10, // selection size
      model_burns: [
        {
          "start_at": "2021-06-11T03:00:00Z", // burn day
          "end_at": "2021-06-11T07:00:00Z",   // burn day
          "burn_name": "Day 1",               // burn day
          "burn_intensity": "high",           // burn item
          "burn_area": 100,                   // burn item
          "fuel_load": 27.2,                  // burn item
          "fuel_type": "forest",              // burn item
          "hr_reference": "HR17112082300",    // ??
          "burn_geometry": {                    // burn item
            "longitude": 151.04105948933585,
            "latitude": -33.516619870517246
          }
        }
      ],
      model_start_at: "2021-06-10T11:00:00Z",     // selection start
      model_end_at: "2021-06-12T20:00:00Z",       // selection end
      is_small: false,                            // selection small/large
      weather_data: "access",                     // selection access/gfs
      processing_finished_at: "2021-06-10T04:42:27.646Z", //
      run_model: true                             // 
    }
  }

  const exportScenarioHandler = () =>{
      exportScenario();
  }

  const submitScenarioHandler = ()=>{
    submitScenario();
  }



  const onRowClickHandler = (rowItemId:string, rowItem:any)=>{
    const selectedIndex = selected.indexOf(rowItemId);
    // console.log(rowItem);
    let newSelected: string[] = [rowItemId];
    if (selectedIndex === 0) {
        newSelected =[];
      }
    setSelected(newSelected); 
    // console.log(newSelected);    
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
 const {onFocusScenariosLayoutTab} = props;
  const rowCount = rows.length;
  const numSelected = selected.length;
  return (
    <div className={classes.root}>
      <Paper>
        <IconButton  title="Export Selection" aria-label="Export Selection" disabled={exportDisabled}  onClick={exportScenarioHandler}>
          <CloudUploadIcon color="primary"  />
        </IconButton>
        <IconButton title="Submit Selection" aria-label="Submit Selection" disabled={submitDisabled}  onClick={submitScenarioHandler}>
          <SubscriptionsIcon color="primary"  />
        </IconButton>
        <StyledSearchBar  className={classes.searchbar}
          value={searched}
          placeholder='Search selections by description'
          onChange={(searchVal:any) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Paper> 
       <Paper className={classes.paper}>
        <ThemeProvider theme={tabletheme}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead classes={classes}>
                <TableRow>
                <TableCell/>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell size="small">Description</TableCell>
                  <TableCell align="right">Start Date</TableCell>
                  <TableCell align="right">End Date</TableCell>
                  <TableCell align="right">Particle Size</TableCell>
                  <TableCell align="right">Domain</TableCell>
                  <TableCell align="right">Weather Source</TableCell>                             
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:any) => {
                  const isItemSelected = isSelected(row.id);
                  return(
                          <Row focusScenariosLayout={onFocusScenariosLayoutTab}  key={row.id} row={row}  isChecked={isItemSelected} onRowClicked={onRowClickHandler}/>
                        );
                    })
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Paper>
    {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
       <div className={classes.root}>
      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </div>
    </div>
  );
}
