import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    root: {
      boxShadow: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },

    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

export default function NavBar() {
  const { oktaAuth, authState } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated;
  const user = authState?.idToken?.claims;

  /**
   * Loks in with redirect with Okta
   */
  const loginWithRedirect = async () => {
    await oktaAuth.signInWithRedirect();
  };

  /**
   * Logs out using Okta
   */
  const logout = async () => {
    await oktaAuth.signOut();
  };

  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const resetLocalStorage = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loginStatus');
    localStorage.removeItem('modelAPI');
  };

  async function getAndSaveToken() {
    try {
      if (isAuthenticated) {
        const accessToken = await oktaAuth.getAccessToken();
        const idTokenClaims = authState?.idToken?.claims;
        /* eslint-disable */
        localStorage.setItem('id_token', JSON.stringify(idTokenClaims) ?? "");
       /* eslint-enable */
        localStorage.setItem('accessToken', accessToken ?? '');
        localStorage.setItem('loginStatus', 'logged-in');
        localStorage.setItem('modelAPI', `${process.env.REACT_APP_MODEL_URL}`);
      } else {
        resetLocalStorage();
      }
    } catch (err) {
      console.log('An error occured while accessing token..');
      console.log(err);
    }
  }

  const callTokenFetchFunction = () => {
    getAndSaveToken();
  };

  useEffect(() => {
    // if(isAuthenticated){
    callTokenFetchFunction();
    // }
  }, [isAuthenticated]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateHomeHandler = () => {
    history.push(`/smokeModels`);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    resetLocalStorage();
    logout();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!isAuthenticated && <MenuItem onClick={handleLogin}>Login</MenuItem>}
      {isAuthenticated && <MenuItem onClick={handleLogout}>Log out</MenuItem>}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ backgroundColor: '#ED1C24' }}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            NSW RFS Smoke Dispersion Modelling
          </Typography>
          <div className={classes.grow} />

          {isAuthenticated && (
            <>
              <div className={classes.sectionDesktop}>
                <Button disabled style={{ textTransform: 'none', color: 'white', backgroundColor: '#ED1C24' }}>
                  {user?.name}
                </Button>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  className={classes.menuButton}
                  title="Log out"
                  onClick={handleLogout}
                  color="inherit"
                >
                  <ExitToAppIcon />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  className={classes.menuButton}
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleLogout}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </>
          )}
          {!isAuthenticated && (
            <>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  className={classes.menuButton}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  title="Log in"
                  onClick={handleLogin}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
