import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Layout from './Layout';
import NavBar from './NavBar';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
    },
  }),
);

const ProtectedApplication = () => {
  const classes = useStyles();
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated || false;

  return isAuthenticated ? (
    <div className={classes.root}>
      <NavBar />
      <Layout />
    </div>
  ) : (
    <div className={classes.root}>
      <NavBar />
    </div>
  );
};

export default ProtectedApplication;