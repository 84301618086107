
/* eslint-disable import/first */

import esriConfig from '@arcgis/core/config';

esriConfig.assetsPath = './assets';

import '@arcgis/core/assets/esri/themes/light/main.css';

import { Security } from '@okta/okta-react';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import oktaAuth from './oktaConfig'; // Import your Okta configuration

const restoreOriginalUri = async (_oktaAuth: unknown, originalUri: string) => {
  // Restore the original URI after login
  window.location.replace(originalUri || '/');
};

ReactDOM.render(
  <React.StrictMode>
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <BrowserRouter>
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </BrowserRouter>
    </Security>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
