/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {useDispatch, useSelector} from  'react-redux';
import { RootState } from 'store';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from "@material-ui/lab/Alert";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle,  Snackbar, TextField } from '@material-ui/core';
import configData from "config.json";
// @ts-ignore
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
import {burnActions} from '../store/burn-slice';

const { stringify } = require('wkt');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    dialogRoot: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formRoot: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    snackbarRoot: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2)
      }
    }
  }),
);

function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BurnButtons() {
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedBurnItem:any = useSelector((state: RootState) => state.burn.selectedBurnPolygon);
  const isBurnSelected = useSelector((state: RootState) => state.burn.isBurnSelected);
  const selectedBurnRowIds = useSelector((state: RootState) => state.burn.selectedBurnRowIds);
  const burns = useSelector((state: RootState) => state.burn.items);

  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const burnGurafText = selectedBurnItem?.guarReference;
  /* eslint-disable */
  const burnDescription = selectedBurnItem?.description === '' ? 'Burn - ' + burnGurafText : selectedBurnItem?.description;
 /* eslint-enable */
  const burnStartDate = selectedBurnItem?.scheduledStartDate;
  const burnEndDate = selectedBurnItem?.scheduledEndDate;
  const burnAgency = selectedBurnItem?.leadAgency;
  const fuelType = selectedBurnItem?.fuelType;
  const fuelLoad = selectedBurnItem?.fuelLoad;
  const burnType = selectedBurnItem?.burnType;
  const burnCoverage = selectedBurnItem?.burnCoverage;

  const descriptionRef = useRef<HTMLInputElement>(null);
  const fuelTypeRef = useRef<HTMLInputElement>(selectedBurnItem?.fuelType);
  const fuelLoadRef = useRef<HTMLInputElement>(selectedBurnItem?.fuelLoad);
  const burnTypeRef = useRef<HTMLInputElement>(selectedBurnItem?.burnType);
  const burnCoverageRef = useRef<HTMLInputElement>(selectedBurnItem?.burnCoverage);
  const guardianURL = selectedBurnItem?.url;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const addBurnItemHandler = () => {
    handleClickOpen();
   
  }

  const removeBurnItemsHandler = ()=>{
      dispatch(burnActions.removeBurnItems(selectedBurnRowIds));
  }
  

  const saveSelectedBurnsHandler = async () => {
    try{
          const burnData = [...burns];
          // const response = await fetch(configData.APIURLS.BURN_URL,
          const response = await fetch(process.env.REACT_APP_BURN_URL!,
          {
            method:'POST',
            body:JSON.stringify(burnData),
            headers:{
              'Accept': 'application/json',
              'Content-Type':'application/json'
            }
          });

          if (response.ok) {
            setMessage("Successfully saved burn data..");
            setSnackbarOpen(true);
            setSeverity("success");

            const getUpdatedBurns = await fetch(process.env.REACT_APP_BURN_URL!,

              {headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              }
            }
            );         
            
            if(getUpdatedBurns.ok){
              const updatedBurnItems = await getUpdatedBurns.json();

              dispatch(burnActions.updateBurnItems(updatedBurnItems));
              dispatch(burnActions.updateAPIBurnItems(updatedBurnItems));

              setMessage("Successfully saved and reloaded burn data..");
              setSnackbarOpen(true);
              setSeverity("success");
            }
            else {
              setMessage("Error fetching updated burns data..");
              setSnackbarOpen(true);
              setSeverity("warning");
              console.log('Error fetching data- burns');
            }

          } else {
            setMessage("Error saving data..");
            setSnackbarOpen(true);
            setSeverity("warning");
          }
          // response.json().then(data => console.log(data));

        }
    catch (err){
          setMessage("Error saving data");
          setSnackbarOpen(true);
          setSeverity("error");
        }

  }

const openGuardianUrl = () => window.open(guardianURL,"_blank");  

  const handleOk = () => {
    const updatedBurnItem = {
      ...selectedBurnItem,
      description: descriptionRef.current?.value ||  descriptionRef.current?.innerText,       
    };

    dispatch(burnActions.addBurnItem(updatedBurnItem));
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = (event:any, reason:any) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <div >
      <IconButton disabled={!isBurnSelected} onClick={addBurnItemHandler} title="Add Burn" aria-label="Add Burn" >
        <AddCircleIcon color="primary" />
      </IconButton>
      <IconButton title="Save Burns" aria-label="Save Burns" onClick={saveSelectedBurnsHandler}>
        <SaveIcon color="primary" />
      </IconButton>        
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Description</DialogTitle>
        <DialogContent>
          <form  noValidate autoComplete="off">
            <div className={classes.root}>         
              <div>       
                <TextField 
                required 
                fullWidth 
                margin="normal"
                id="descriptionText" 
                label="Description" 
                inputRef={descriptionRef}        
                defaultValue={burnDescription} />        
              </div>
              <div>       
                <TextField disabled id="guarRefIdText" label="GUAR Ref ID" defaultValue={burnGurafText} />                
                <TextField  disabled id="fuelTypeText" label="Fuel Type" defaultValue={fuelType} />      
              </div>
              <div>       
                <TextField disabled id="startDateText" label="Start Date" defaultValue={burnStartDate} />                
                <TextField  disabled id="fuelLoadText" label="Fuel Load" defaultValue={fuelLoad} />      
              </div>
              <div>       
                <TextField disabled id="endDateText" label="End Date" defaultValue={burnEndDate} />                
                <TextField disabled required id="burnTypeText"  label="Burn Type" defaultValue={burnType} />       
              </div>
              <div>       
                <TextField disabled id="agencyText" label="Agency" defaultValue={burnAgency} />
                <TextField disabled required id="burnCoverageText" label="Burn Coverage" defaultValue={burnCoverage} />                      
              </div>
              <div>
                <Button disabled={!guardianURL}  color="primary"  onClick = {openGuardianUrl}>Open record in Guardian</Button>               
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} color="primary">
            OK
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.snackbarRoot}>
      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>     
    </div>
    </div>
  );
}