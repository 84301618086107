/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import MuiAlert from "@material-ui/lab/Alert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import RoomIcon from '@material-ui/icons/Room';
import QueueIcon from '@material-ui/icons/Queue';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AddBurnPolygonGraphic } from 'MapCommands';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PropTypes from 'prop-types'; 

import { Checkbox, createTheme, createStyles, FormControlLabel, Snackbar, Switch, TablePagination, TableSortLabel, TextField, Theme, ThemeProvider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { burnActions } from "store/burn-slice";
import SearchBar from "material-ui-search-bar";
// @ts-ignore
import styled from "styled-components";
import moment from "moment";
import Polygon from "@arcgis/core/geometry/Polygon";

import configData from "config.json";
// @ts-ignore
import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';
// @ts-ignore
import _ from 'lodash';
import {formatTimeforTableCell as formatTime, formatForDTPicker} from '../helper/helperService';

import EditableTableCellText from "./EditableElements";

const wkt = require('wkt');
const { parse } = require('wkt');

const tabletheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {  
        padding: '1px 1px 1px 1px',
      },
    },
  }
});

function descendingComparator(a:any, b:any, orderBy:any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
  // if(!isDate){
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1;
  //   }
  //   if (b[orderBy] > a[orderBy]) {
  //     return 1;
  //   }
    
  // }
  // if(isDate){
  //   if (moment(b[orderBy]).isBefore(moment(a[orderBy]))) {
  //       return -1 
  //    }
  //    if (moment(b[orderBy]).isAfter(moment(a[orderBy]))) {
  //    return 1;
  // }
// } 
// return 0;
}

function getComparator(order:any, orderBy:any) {
  return order === 'desc'
    ? (a:any, b:any) => descendingComparator(a, b, orderBy)
    : (a:any, b:any) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array:any, comparator:any) {
  const stabilizedThis = array.map((el:any, index:any) => [el, index]);
  stabilizedThis.sort((a:any, b:any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el:any) => el[0]);
}

const headCells = [
  // {
  //   id: '',
  //   numeric: false,
  //   disablePadding: true,
  //   label: '',
  // },
  // {
  //   id: '',
  //   numeric: false,
  //   disablePadding: true,
  //   label: '',
  // },
  {
    id: 'description',
    numeric: false,
    disablePadding: true,
    label: 'Description',
  },
  {
    id: 'guarRef',
    numeric: false,
    disablePadding: false,
    label: 'Guar Ref',
  },
  {
    id: 'scheduledStartDate',
    numeric: true,
    disablePadding: false,
    label: 'Scheduled Start Date',
  },
  {
    id: 'scheduledEndDate',
    numeric: true,
    disablePadding: false,
    label: 'Scheduled End Date',
  },
  {
    id: 'activityType',
    numeric: false,
    disablePadding: false,
    label: 'Activity Type',
  },
  {
    id: 'burnCoverage',
    numeric: false,
    disablePadding: false,
    label: 'Burn Coverage',
  },
  {
    id: 'burnIntesity',
    numeric: false,
    disablePadding: false,
    label: 'Burn Intensity',
  },
  {
    id: 'burnType',
    numeric: false,
    disablePadding: false,
    label: 'Burn Type',
  },
];

export const StyledSearchBar = styled(SearchBar)`
  max-width: 475px;
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    searchbar:{
      borderColor: 'orange',
      marginBottom: theme.spacing(2)
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    tableRow: {
      height: 2
    },
    tableCell: {
      padding: "2px"
    },
    rowRoot: {
      padding:"2px",
      "& > *": {
        borderBottom: "unset"
      },
    },
    disabledButton:{
        style:{color:'grey'}
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 220,      
    },
   
    snackbarRoot: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2)
      }
    }
  })
);

function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const  BurnDayRow = (props: { row: any ;burnListRow:any})=>{

const{row,burnListRow} = props;
const dispatch = useDispatch();
const classes = useStyles();
const formattedStartTime = formatForDTPicker(burnListRow.startDateTime); 
const formattedEndTime = formatForDTPicker(burnListRow.endDateTime); 
const [burnDayStartTime,setBurnDayStartTime ] = useState(formattedStartTime);
const [burnDayEndTime,setBurnDayEndTime ] = useState(formattedEndTime);
const [errorTextStartTime, setErrorTextStartTime] = useState('');
const [errorTextEndTime, setErrorTextEndTime] = useState('');

const getMinBurnDayStartTimeOnDelete:any = (burnDayStartTimeVal :any) =>{
  let moments =  row.burnList.map((b:any) => b.startDateTime);
  const oldStartTime = `${moment(burnDayStartTimeVal).creationData().input?.toString().split(':00Z')[0]}:00Z`;
  moments  = moments.filter((item:any) =>  item !==oldStartTime );
//   moments  = moments.filter(function(item:any) {
//     return item !== burnDayStartTimeVal
// }); 
const newMoments = moments.map((t:any)=>moment(t));
  const minDate = moment.min(newMoments);
  return minDate;
 }

 const getMaxBurnDayEndTimeOnDelete:any = (burnDayEndTimeVal :any) =>{
  let moments =  row.burnList.map((b:any) => b.endDateTime);
  const oldEndTime = `${moment(burnDayEndTimeVal).creationData().input?.toString().split(':00Z')[0]}:00Z`;
  moments  = moments.filter((item:any) =>  item !==oldEndTime );
  const newMoments = moments.map((t:any)=>moment(t));
  const maxDate = moment.max(newMoments);
  return maxDate;
 }

  const onRemoveBurnDayItem = (burnRow:any, burnDayId:string, bunrDayStarTimeOld:any, burnDayEndTimeOld:any) => {
    const rowId = row.id;
    const burnId = burnDayId;
    if(burnRow.guarReference !== 'BA-9999999999999'){
  //     console.log( burnRow.burnList[burnId]);
  //    const burnDaystartDateTime =   burnRow.burnList[burnId].startDateTime;
  //    const burnDayendDateTime = burnRow.burnList[burnId].endDateTime;
  const minStartDate = getMinBurnDayStartTimeOnDelete(bunrDayStarTimeOld);
  const maxEndDate = getMaxBurnDayEndTimeOnDelete(burnDayEndTimeOld);

  //    const updatedMaxburnTime = `${moment(maxEndDate, "YYYY-MM-DD HH:mm")?.utcOffset(0, false)?.toISOString()?.split('.')[0]}Z`;
   dispatch(burnActions.updateRowItemScheduledEndDate({
     id:row.id,
     updatedScheduledEndTime: maxEndDate
   }));
  
  //  const updatedMinburnTime = `${moment(minStartDate, "YYYY-MM-DD HH:mm")?.utcOffset(0, false)?.toISOString()?.split('.')[0]}Z`;
        dispatch(burnActions.updateRowItemScheduledStartDate({
          id:row.id,
          updatedScheduledStartTime: minStartDate
        }));

    }

    dispatch(burnActions.removeSelectedBurnDay({
      id:row.id,
      burnDayId    
    }));  
   }

   const getMinBurnDayStartTime:any = (newBurnDayStartTime :any,oldBurnDayStartTime:any) =>{
    let moments =  row.burnList.map((b:any) =>b.startDateTime);
    const oldStartTime = `${moment(oldBurnDayStartTime).creationData().input?.toString().split(':00Z')[0]}:00Z`;
    moments  = moments.filter((item:any) =>  item !==oldStartTime );
    moments.push(newBurnDayStartTime);
    const newMoments = moments.map((t:any)=>moment(t));
    const minDate = moment.min(newMoments);
    return minDate;
   }

   const getMaxBurnDayEndTime:any = (newBurnDayEndTime :any, oldBurnDayEndTime:any) =>{
    let moments =  row.burnList.map((b:any) => b.endDateTime);
    const oldTime = `${moment(oldBurnDayEndTime).creationData().input?.toString().split(':00Z')[0]}:00Z`;
    moments  = moments.filter((item:any) =>  item !==oldTime );
    moments.push(newBurnDayEndTime);
    const newMoments = moments.map((t:any)=>moment(t));
    const maxDate = moment.max(newMoments);
    return maxDate;
   }

  const handleStartTimeChange = (event:any)=>{
    const updatedValue = moment(event.target.value, "YYYY-MM-DD HH:mm")
    if(updatedValue.isValid()){
      setBurnDayStartTime(event.target.value);      
    }
   }
   
   const handleStartTimeBlur = (event:any, burnDayItemId:any, burnListItemStartTime:any,updatedPropertyName:string,burnListItemEndTime:any) => {
    const updatedValue = moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true);
    if (updatedValue.isAfter(burnListItemEndTime)){
      setErrorTextStartTime('Start time cannot be after End time!!');
      setTimeout(()=>{
        setErrorTextStartTime('');
        setBurnDayStartTime(formatForDTPicker(burnListItemStartTime))
      }, 2000);        
      return;
    }
    // if (!moment(burnListItemStartTime).isSame(updatedValue,'day')){ // already existing burn date should be editable
    //     const sameDay = row.burnList.find((burnDayItem:any)=> moment(burnDayItem.startDateTime).utcOffset(0, true).isSame(updatedValue,'day'));
    //     if(sameDay){      
    //       setErrorTextStartTime('Duplicate burn days!!');
    //       setTimeout(()=>{
    //         setErrorTextStartTime('');
    //         setBurnDayStartTime(formatForDTPicker(burnListItemStartTime))
    //       }, 2000);        
    //       return;
    //     }
    //   }
    setErrorTextStartTime('');
    const updatedDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm")?.utcOffset(0, true)?.toISOString()?.split('.')[0]}Z`;
    if(updatedDateTime!==burnListItemStartTime){
      dispatch(burnActions.updateBurnDayItem({
        id:row.id,
        burnDayId:burnDayItemId,
        updatedValue:updatedDateTime,
        property:updatedPropertyName
      }));
      
      if(row.guarReference !== 'BA-9999999999999'){
      const minburnTime = getMinBurnDayStartTime(moment(updatedValue),burnListItemStartTime);
      // const minburnTime = getMinBurnDayStartTime(moment(updatedValue));
      // console.log(`${minburnTime.creationData().input}:00Z`);
      const updatedMinburnTime = `${moment(minburnTime, "YYYY-MM-DD HH:mm")?.utcOffset(0, true)?.toISOString()?.split('.')[0]}Z`;
     //  console.log(updatedMinburnTime);
      dispatch(burnActions.updateRowItemScheduledStartDate({
        id:row.id,
        updatedScheduledStartTime: updatedMinburnTime
      }));
    }
  }
   }

   const handleEndTimeChange = (event:any)=>{
    const updatedValue = moment(event.target.value, "YYYY-MM-DD HH:mm");
    if(updatedValue.isValid()){
      setBurnDayEndTime(event.target.value);
    }
   }
 
   const handleEndTimeBlur = (event:any, burnDayItemId:any, burnListItemEndTime:any,updatedPropertyName:string,burnListItemStartTime:any ) => {
    // const timeClone =moment(event.target.value).clone();
    const updatedValue = moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true);
    if (moment(updatedValue).isBefore(burnListItemStartTime)){         
          setErrorTextEndTime('End time cannot be before start time!!');
          setTimeout(()=>{
            setErrorTextEndTime('');
            setBurnDayEndTime(formatForDTPicker(burnListItemEndTime))
          }, 2000);        
          return;        
      }
      setErrorTextEndTime('');

     const updatedDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString().split('.')[0]}Z`;
     if(updatedDateTime!==burnListItemEndTime){
       dispatch(burnActions.updateBurnDayItem({
         id:row.id,
         burnDayId:burnDayItemId,
         updatedValue:updatedDateTime,
         property:updatedPropertyName
       }));
       if(row.guarReference !== 'BA-9999999999999'){ // only for Guardian burns
       const maxburnTime = getMaxBurnDayEndTime(moment(updatedValue), moment(burnListItemEndTime));
       
      //  console.log(`${maxburnTime.creationData().input.split(':00Z')[0]}:00Z`);
      const updatedMaxburnTime =`${maxburnTime.creationData().input.split(':00Z')[0]}:00Z`; //  `${moment(maxburnTime, "YYYY-MM-DD HH:mm")?.utcOffset(0, true)?.toISOString()?.split('.')[0]}Z`;
     // console.log(updatedMaxburnTime);
      dispatch(burnActions.updateRowItemScheduledEndDate({
        id:row.id,
        updatedScheduledEndTime: updatedMaxburnTime
      }));
     }
    }
   }

   const handleAreaBlur = (updatedText: any, rowId: string, burnDayItemId:any, burnListItemArea:any,updatedPropertyName:string) =>{  
    if(updatedText!==burnListItemArea){
        dispatch(burnActions.updateBurnDayItem({
          id:row.id,
          burnDayId:burnDayItemId,
          updatedValue:updatedText,
          property:updatedPropertyName
        }));
      }
  }
   
  return(
    <TableRow key={burnListRow.id}>
       <TableCell>
        <IconButton color="primary" disabled={row.burnList.length<=1} title="Remove Burn Day" onClick={() => {onRemoveBurnDayItem(row,burnListRow.id,burnListRow.startDateTime,burnListRow.endDateTime)}} aria-label="remove burn day" component="span">
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <TableCell  component="td" scope="row">
      <TextField
          id="datetime-local"
          label="Start time"
          type="datetime-local"
          onChange={(event)=>{handleStartTimeChange(event)}}
          onBlur= {(event)=>{handleStartTimeBlur(event,burnListRow.id,burnListRow.startDateTime, "startDateTime",burnListRow.endDateTime)}}
          // defaultValue={burnDayStartTime} 
           value={burnDayStartTime}         
          className={classes.textField}
           error={!!errorTextStartTime}
          // error={errorTextStartTime.length > 0}
          size="small"
          helperText={errorTextStartTime}
          inputProps={{style: {fontSize: 12}}}
          InputLabelProps={{
            shrink: true,
            style: {fontSize: 12},
          }
        }
      />
      </TableCell>
      <TableCell  component="td" scope="row">
      <TextField
          id="datetime-local"
          label="End time"
          type="datetime-local"  
          onChange={(event)=>{handleEndTimeChange(event)}}       
          onBlur= {(event)=>{handleEndTimeBlur(event,burnListRow.id,burnListRow.endDateTime, "endDateTime",burnListRow.startDateTime )}}
          value={burnDayEndTime}  
          helperText={errorTextEndTime} 
          error={!!errorTextEndTime}     
          // error={errorTextEndTime.length > 0}  
          size="small"
          className={classes.textField}
          inputProps={{style: {fontSize: 12}}}
          InputLabelProps={{
            shrink: true,
            style: {fontSize: 12},
          }
        }
      />
      </TableCell>
      <EditableTableCellText
       onBlur={(event:any)=>{handleAreaBlur(event, row.id, burnListRow.id,burnListRow.area, "area")}}
      >
        <TableCell >
          {burnListRow.area}
        </TableCell>
      </EditableTableCellText>         
    </TableRow>
  )
}

// function Row(props: { row: any ; mapDispatcher:any; isChecked:boolean;onBurnItemUpdated:(updatedBurnId:any, isBurnSame:boolean)=>void; onRowClicked: (rowId:string, rowItem: any)=>void}) {
function Row(props: { row: any ; mapDispatcher:any; isChecked:boolean; onRowClicked: (rowId:string, rowItem: any)=>void}) {
  const dispatch = useDispatch();
  const { row, isChecked, mapDispatcher, onRowClicked} = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  // const burns = useSelector((state: RootState) => state.burn.items);
  const selections = useSelector((state: RootState) => state.burn.scenarioItems);
  const selectedScenario = useSelector((state: RootState) =>   state.burn.selectedScenario);
  const burnDataAPI = useSelector((state: RootState) =>   state.burn.burnItemsAPI);
  const onZoomToBurnPolyHandler = (burnRow: any) =>{

  const geometryVal = burnRow.geometry;
  if (geometryVal === null) return;
  const wktGeom = wkt.parse(geometryVal);
  const esriGeom = geojsonToArcGIS(wktGeom);
  
  // when converting back to esri polygon, somehow spatailReference is changed to 4826, so setting it back to 102100
  const polygon = new Polygon({  
    rings: esriGeom.rings,
    spatialReference: {
      wkid: 102100 
    }
  });
  
  if (mapDispatcher) {
    mapDispatcher.dispatch(
      new AddBurnPolygonGraphic(
        polygon,
      ),
    );

  }
}

const iconDisabledTheme = createTheme({
  palette: {
    action: {      
      disabled: 'gray'
    }
  }
});
const rowIdValue = row.id;

// if the burn is in any selection disable delete for that burn item
const canRemoveBurnItem = _(selections)
                          .flatMap('burns')
                          .find({id: rowIdValue});


const aPIburnItem = burnDataAPI.find((burnItem:any)=>burnItem.id === row.id);
const isBurnSame = _.isEqual(row,aPIburnItem);
const isDisabledAddBurnToScenario = (selectedScenario.burns.filter((scenarioBurnItem:any)=>scenarioBurnItem.id === row.id).length > 0) || !isBurnSame;


const getMinBurnDayStartTime:any = (newBurnDayStartTime :any) =>{
  const moments =  row.burnList.map((b:any) => moment(b.startDateTime));
  moments.push(newBurnDayStartTime);
  const minDate = moment.min(moments);
  return minDate;
 }

 const getMaxBurnDayEndTime:any = (newBurnDayEndTime :any) =>{
  const moments =  row.burnList.map((b:any) => moment(b.endDateTime));
  moments.push(newBurnDayEndTime);
  const maxDate = moment.max(moments);
  return maxDate;
 }

 const updateScheduledDateValuesOnAdd = (newStartTime: any, newEndTime: any) =>{
  const maxBurnEndTime = getMaxBurnDayEndTime(newEndTime);
  const minBurnStartTime = getMinBurnDayStartTime(newStartTime);
  
   const updatedMaxburnTime = `${moment(maxBurnEndTime, "YYYY-MM-DD HH:mm")?.utcOffset(0, false)?.toISOString()?.split('.')[0]}Z`;
   dispatch(burnActions.updateRowItemScheduledEndDate({
     id:row.id,
     updatedScheduledEndTime: updatedMaxburnTime
   }));
  
   const updatedMinburnTime = `${moment(minBurnStartTime, "YYYY-MM-DD HH:mm")?.utcOffset(0, false)?.toISOString()?.split('.')[0]}Z`;
        dispatch(burnActions.updateRowItemScheduledStartDate({
          id:row.id,
          updatedScheduledStartTime: updatedMinburnTime
        }));

 }

const onAddNewBurn = (burnRow:any) => {
  const rowId = burnRow.id;
  const burnDayStartTimes = burnRow.burnList.map((burnListItem:any)=> moment(burnListItem.startDateTime).utcOffset(0, true));
  const maxDate = moment.max(burnDayStartTimes);
  const newDate = maxDate.add(1,'days').utcOffset(0, true).startOf('day').add(10,'hour');
  const newDateClone = newDate.clone();
  const newDateEndTime = newDateClone.add(7,'hour');
  
  const rowStartTime = `${moment(newDate, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString().split('.')[0]}Z`;
  const rowEndTime = `${moment(newDateEndTime, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString().split('.')[0]}Z`;
  dispatch(burnActions.addNewBurnDay({
    id:rowId,
    burnRowStartTime:rowStartTime,
    burnRowEndTime:rowEndTime,
    areaHa:burnRow.areaHa
  }));

  if(row.guarReference !== 'BA-9999999999999'){
    updateScheduledDateValuesOnAdd(newDate,newDateEndTime);
}
  
}

const handleCheckboxClick =(event: any, id: string, rowItem:any) => {
  onRowClicked(id, rowItem);
}

async function removeSelectedBurn(burnRowId:string){
  try{
      
      const response = await fetch(`${process.env.REACT_APP_BURN_URL!}/${burnRowId}`,
        {
          method:'DELETE'
        });

      if (response.ok) {
          setMessage("Successfully deleted burn..");
          setSnackbarOpen(true);
          setSeverity("success");
          dispatch(burnActions.removeBurnItems([row.id]));

        } else {
          setMessage("Error saving burn data after delete..");
          setSnackbarOpen(true);
          setSeverity("warning");
        }
      }
      catch (err){
        setMessage("Error saving burn data after delete..");
        setSnackbarOpen(true);
        setSeverity("error");
      }
  }

  const onRemoveBurn = (burnRowId: string)=>{
      removeSelectedBurn(burnRowId);
  }

  const addBurnToSelectionHandler = (burnRow:any) =>{
    dispatch(burnActions.addBurnToSelectedScenario(burnRow));
  }
  
  const isItemSelected = isChecked;

  const handleSnackbarClose = (event:any, reason:any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

const formattedScheduledStartTime = formatTime(row.scheduledStartDate);
const formattedScheduledEndTime = formatTime(row.scheduledEndDate);
  
return (
    <>
      <TableRow 
        hover      
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onChange={(event) => handleCheckboxClick(event, row.id, row)}
            role="checkbox"
             checked={isItemSelected}
            // checked={isItemSelected && !isDisabledAddBurnToScenario }
            inputProps={{ "aria-labelledby": row.id }}
            // disabled={isDisabledAddBurnToScenario} 
          />
        </TableCell>
        <TableCell>
          <IconButton color="primary" title="Zoom to burn polygon" onClick={() => {onZoomToBurnPolyHandler(row)}} aria-label="zoom to burn polygon" component="span">
            <RoomIcon />
          </IconButton>
        </TableCell>
        
        <TableCell>
          {/* <IconButton disabled={canRemoveBurnItem?.id} style={{ color: '#A93226' }} title="Remove burn" onClick={() => {onRemoveBurn(row.id)}} aria-label="remove burn" component="span"> */}
          <IconButton color="secondary" disabled={canRemoveBurnItem?.id}  title="Remove burn" onClick={() => {onRemoveBurn(row.id)}} aria-label="remove burn" component="span">
            <DeleteForeverIcon />
          </IconButton>
        </TableCell>
        
        <TableCell>
          <IconButton disabled={isDisabledAddBurnToScenario} color="primary" title="Add burn to selection" onClick={() => {addBurnToSelectionHandler(row)}} aria-label="add tto Selection" component="span">
            <PlaylistAddIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>        
        <TableCell component="th" >
        { (!isBurnSame) && (<IconButton disabled style= {{color: 'green'}} component="span">
            <NewReleasesIcon />
        </IconButton>) }
        {row.description}
        </TableCell>
        <TableCell align="right">{row.guarReference}</TableCell>
        <TableCell align="right">{formattedScheduledStartTime}</TableCell>
        <TableCell align="right">{formattedScheduledEndTime}</TableCell>
        <TableCell align="right">{row.activityType}</TableCell>
        <TableCell align="right">{row.burnCoverage}</TableCell>
        <TableCell align="right">{row.burnIntesity}</TableCell>
        <TableCell align="right">{row.burnType}</TableCell>
        </TableRow>
      <TableRow 
      hover
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ paddingLeft: "2px", paddingTop: 0 }}>
              <Typography  variant="h6" gutterBottom component="div">
                Days
              </Typography>
              <Table size="small" aria-label="burns">
                <TableHead>
                  <TableRow  className={classes.tableRow} >
                    <TableCell>
                      <IconButton color="primary" title="Add new burnDay" onClick={() => {onAddNewBurn(row)}} aria-label="Add new burn day" component="span">
                      {/* <IconButton disabled={row.burnList.length>=4} color="primary" title="Add new burnDay" onClick={() => {onAddNewBurn(row)}} aria-label="Add new burn day" component="span"> */}
                        <QueueIcon />
                      </IconButton>
                    </TableCell>        
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>                   
                    <TableCell align="left">Area(Ha)</TableCell>                              
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.burnList.map((burnListRow:any) => {
                    return(
                      <BurnDayRow  key={burnListRow.id} row={row}  burnListRow={burnListRow}/>       
                      ); 
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <div className={classes.snackbarRoot}>
      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
    </>
  );
}

function EnhancedTableHead(props:any) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property:any) => (event:any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead >
            <TableRow >
            <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all burns" }}
            />
          </TableCell>
          <TableCell/>
          <TableCell />
          <TableCell />
          <TableCell/> 
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align= {headCell.id==='description' ? 'left' : 'right'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" 
                display="none"
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function BurnsTable(props:{mapDispatcher:any}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('description');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [disabledIds,setDisabledIDs]= useState<string[]>([]);
  const burnData = useSelector((state: RootState) =>   state.burn.items);  
  const [rows, setRows] = useState<any>(burnData);
  const [searched, setSearched] = useState<string>("");

  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);  
  const [dense, setDense] = React.useState(false);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { mapDispatcher } = props;

  const requestSearch = (searchedVal: string) => {
    const filteredRows = burnData.filter((row:any) => {
      return row.description.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    dispatch(burnActions.updateSelectedBurnRowIDs(selected));
  }, [selected]);

  useEffect(()=>{
    setRows(burnData);
  },[burnData]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n:any) => n.id);    
      setSelected(newSelecteds);       
      return;
    }
    setSelected([]);
  };

  const reselectSelectAllHandler = (disabledBurnId:string, isBurnSame:boolean) => {
    const disabledIdsCopy = [...disabledIds];
    if(isBurnSame){
      /* eslint-disable */
      disabledIdsCopy.indexOf(disabledBurnId)>=0 && disabledIdsCopy.splice(disabledIdsCopy.indexOf(disabledBurnId), 1); 
    }else{
      disabledIdsCopy.indexOf(disabledBurnId) === -1 && disabledIdsCopy.push(disabledBurnId);
     
       /* eslint-enable */
    }
    setDisabledIDs(disabledIdsCopy);
    const selectedCopy = [...selected];
    const updatedSelected = selectedCopy.filter((burnId:string) => !disabledIds.includes(burnId));
    setSelected(updatedSelected);
  }

  const onRowClickHandler = (rowItemId:string, rowItem:any)=>{
    const selectedIndex = selected.indexOf(rowItemId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowItemId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected); 
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
 
  const rowCount = rows.length;
  const numSelected = selected.length;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <ThemeProvider theme={tabletheme}>
      
      <StyledSearchBar  className={classes.searchbar}
          value={searched}
          placeholder='Search burns by description'
          onChange={(searchVal:any) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      <TableContainer component={Paper}>
      
        <Table   aria-label="collapsible table">
        <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
          <TableBody>
            {
              stableSort(rows, getComparator(order, orderBy))
            // rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row:any) => {
              const isItemSelected = isSelected(row.id);
              return(
                <Row mapDispatcher={mapDispatcher} key={row.id} row={row}  isChecked={isItemSelected}  onRowClicked={onRowClickHandler}/>               
                );
              })
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>      
      </TableContainer>
      </ThemeProvider>
      <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </div>
  );
}
