import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

const issuer = process.env.REACT_APP_OKTA_ISSUER as string;
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID as string;

// Define your configuration options
const oktaAuthConfig: OktaAuthOptions = {
  clientId,
  issuer,
  redirectUri: `${window.location.origin}`,
  responseMode: 'fragment',
  scopes: ['openid', 'profile', 'email'],
};

// Instantiate OktaAuth
const oktaAuth = new OktaAuth(oktaAuthConfig);

export default oktaAuth;
