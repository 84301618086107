/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MapCommand } from 'MapCommands';
import { useDispatch } from 'react-redux';
import { burnActions } from 'store/burn-slice';
import { Route, Switch, useHistory } from 'react-router-dom';
import ProtectedApplication from 'components/ProtectedApplication';

// ! Okta changes
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import oktaAuth from 'oktaConfig';

export function Loading() {
  return (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function App() {
  const dispatch = useDispatch();

  async function fetchBurnData() {
    try {
      const getUpdatedBurns = await fetch(process.env.REACT_APP_BURN_URL!, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const newBurnData = await getUpdatedBurns.json();
      dispatch(burnActions.updateBurnItems(newBurnData));
      dispatch(burnActions.updateAPIBurnItems(newBurnData));
    } catch (err) {
      console.log('error while fetching burns - App');
      console.log(err);
    }
  }

  async function fetchScenarioData() {
    try {
      const getUpdatdScenarios = await fetch(process.env.REACT_APP_SCENARIO_URL!, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const refreshedScenarioData = await getUpdatdScenarios.json();

      dispatch(burnActions.updateScenarioItems(refreshedScenarioData));
    } catch (err) {
      console.log('error while fetching scenarios - App');
      console.log(err);
    }
  }

  async function fetchModelData() {
    try {
      const getUpdatdScenarios = await fetch(process.env.REACT_APP_MODEL_URL!, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const refreshedModelData = await getUpdatdScenarios.json();
      dispatch(burnActions.updateModelItems(refreshedModelData));
    } catch (err) {
      console.log('error while fetching models - App');
      console.log(err);
    }
  }

  useEffect(() => {
    fetchBurnData();
    fetchScenarioData();
    fetchModelData();
  }, []);

  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() =>
          oktaAuth.isLoginRedirect() ? <LoginCallback /> : <SecureRoute path="/" component={ProtectedApplication} />
        }
      />
    </Switch>
  );
}

export default App;
